@import '../../../../styles/constants.scss';
.cls1 { /*!*/ }
.cls2 {
composes: cls1;

font-size: 12px;
cursor: pointer;
padding: 4px;
& + & {
margin-top: 8px;
}
&:hover {
background-color: rgba($color-gray, 0.5);
}
}