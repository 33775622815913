.cls1 { /*!*/ }
.cls2 {
composes: cls1;

position: relative;
margin: 0 12px;
background: #f5f5f5;
width: 515px;
height: 390px;
padding-top: 105px;
padding-bottom: 80px;
display: flex;
justify-content: space-between;
align-items: center;
flex-direction: column;
border-bottom: solid 8px transparent;
color: inherit;

&.type-specialist {
border-color: #54c0eb;
}

&.type-worker {
border-color: #40596b;
}

&.type-assessment {
border-color: #f5a522;
}
&.disabled {
opacity: 50%;
cursor: default;
}
}