@import '../../styles/mixins.scss';
.cls1 { /*!*/ }
.cls2 {
composes: cls1;

@include lato-font();
height: 100%;
border-left: solid 1px #d9d9d9;
border-right: solid 1px #d9d9d9;
text-align: center;
&.bold {
font-weight: bold;
}
}