.cls1 { /*!*/ }
.cls2 {
composes: cls1;

font-size: 24px;
font-weight: bold;
margin-top: 0;
margin-left: 22px;
overflow: hidden;
line-height: 32px;
margin-bottom: 18px;
word-wrap: break-word;
}