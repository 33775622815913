.cls1 { /*!*/ }
.cls2 {
composes: cls1;

position: relative;
display: flex;
justify-content: center;
align-items: center;
width: 1129px;
min-height: calc(100vh - 124px);
padding: 40px 0 60px;
margin: 0 auto;
max-height: 735px;
}