.cls1 { /*!*/ }
.cls2 {
composes: cls1;

cursor: pointer;
flex-shrink: 0;
margin-right: 6px;
&.descension {
transform: rotate(180deg);
}
}