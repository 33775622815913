.cls1 { /*!*/ }
.cls2 {
composes: cls1;

max-height: 400px;
min-width: 192px;
max-width: 300px;
display: flex;
flex-direction: column;
padding-bottom: 15px;
}