.cls1 { /*!*/ }
.cls2 {
composes: cls1;

font-size: 12px;
align-items: center;
justify-content: center;
&.level-3 {
background: #caecfc;
}
&.level-2 {
background: #f4fbff;
}
&.level-1 {
background: #ffffff;
}

&.actual-1 {
background: #fff0f1;
}

&.actual {
background: #f7d5d4;
}
}